import React, { useState } from "react";
import "./Scholib_home.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faLocation,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";

import {
  faPhone,
  faEnvelope,
  faLocationDot,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import SearchComponent from "./Scholib_search";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Terms from "./Terms";
import { useSelector } from "react-redux";

const Scholib_home = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  const allSchools = useSelector((state) => state.Scholib.scholib.payload.schools);

  const schoolsArray = allSchools.map((school)=>{
    return school.info;
  })

  const [searchee , showSearch ] = useState(false);

  function handleSearchFocus (){
    document.body.classList.toggle('dshauda-hidden');
    showSearch(!searchee);
  }

  const scholib = useSelector((state)=> state.Scholib.scholib.payload);




  return (
    <div className="jhdtqq8">

      {searchee && <SearchComponent suggestions={schoolsArray} search10={handleSearchFocus} /> }

      
      <div className="main">
        <div className="search_box flex1">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <p className="h3 d-inline-block mx-3"> Discover </p>
        </div>

        <p className="h5 d-block mt-1 mb-5 text-center w300">
          Search for your school...
        </p>

        <div className="search-component">
          <input type="text" placeholder="Search..." onFocus={handleSearchFocus} />
        </div>

        <div className="login1">
         <Link to='/register'>  <button className="yyy" style={{borderRadius: '2px'}}> Register your school </button> </Link>


          <div className="line my-5"></div>

          <section className="myInfo text-left">
          <div className="upper d-flex">
            {scholib && <div className="image">
              <img src={scholib.logo.secure_url} style={{objectFit: 'contain'}} alt="" />
            </div>}
            <div className="info ms-2">
              <p className="h6 text-secondary w600"> Scholib </p>
              <p className="h7"> Making Things Easy </p>
            </div>
          </div>

          <hr style={{marginTop:'30px'}}/>

          <div className="below flex4">
            <div className="left each">
              <p> <FontAwesomeIcon icon={faBriefcase} style={{marginRight: '6px'}} /> Scholib Tech Pvt. Ltd </p>
              <p> <FontAwesomeIcon icon={faLocation} style={{marginRight: '6px'}} /> Buddhashanti 2 Budhabare Jhapa </p>
            </div>
            <div className="right each">
            <p> <FontAwesomeIcon icon={faPhone} style={{marginRight: '6px'}} /> 9806014021 </p>
              <p> <FontAwesomeIcon icon={faEnvelope} style={{marginRight: '6px'}} /> contact@scholib.com </p>
            </div>
          </div>
        </section>

        <section className="about-box">
          <p className="h5 w600"> Why Choose Us </p>

          <p className='lastP'>
          At Scholib, we revolutionize school operations through innovative web and ERP solutions tailored for the education sector. Our cutting-edge platforms leverage the latest technologies to streamline processes, enhance collaboration, and provide powerful insights. With a team of seasoned experts deeply versed in the unique needs of schools, we craft customized systems that exceed expectations. Choose Scholib for our unparalleled expertise in developing user-friendly, feature-rich solutions that elevate the educational experience for administrators, teachers, students, and parents alike. Join our growing community of satisfied educational institutions empowered by Scholib's commitment to excellence.
          </p>

        </section>



          <section className="contact">
            <div className="four">
              <p className="h4 w600 tc"> Contact Us </p>

              <div className="main width flex4">
                <div className="left width flex2">
                  <div className="content flex1">
                    <FontAwesomeIcon icon={faPhone} />
                    <p className="h6 w700">PHONE</p>
                    <p className="h7 w700">
                      &nbsp; <span> 9806014021</span>
                    </p>
                    <p className="h7 w700">
                      &nbsp; <span> 9816037076 </span>
                    </p>
                  </div>
                  <div className="content flex1">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <p className="h6 w700">EMAIL</p>
                    <p className="h7 w700">
                      &nbsp; <span> contact@scholib.com</span>
                    </p>
                    <p className="h7 w700">
                      &nbsp; <span> milanbhattarai007@gmail.com </span>
                    </p>
                  </div>
                  <div className="content flex1">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <p className="h6 w700">LOCATION</p>
                    <p className="h7 w700">
                      &nbsp;
                      <span>
                        Buddhashanti 2 Budhabare jhapa
                      </span>
                    </p>
                  </div>
                  <div className="content flex1">
                    <FontAwesomeIcon icon={faLink} />
                    <p className="h6 w700">SOCIAL MEDIA</p>

                    <div className="logos flex1">
                      {/* <a href={"facebook.com"}>
                        <p className="h3">
                          <FontAwesomeIcon icon={faFacebook} />
                        </p>
                      </a> */}

                      <a href={"https://www.instagram.com/scholib.official"}>
                        <p className="h3">
                          <FontAwesomeIcon icon={faInstagram} />
                        </p>
                      </a>

                      <a href={"https://www.x.com/scholib"}>
                        <p className="h3">
                          <FontAwesomeIcon icon={faTwitter} />
                        </p>
                      </a>

                      <a href={"https://www.youtube.com/@ujjwal.sapkota"}>
                        <p className="h3">
                          <FontAwesomeIcon icon={faYoutube} />
                        </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="right width flex1">


                   <iframe
                    title="Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3091.59696324769!2d88.03536198534188!3d26.72261185961176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e5b707bb4dee8b%3A0xa28189dcc8d781c4!2sBudhabare!5e0!3m2!1sen!2snp!4v1724999471907!5m2!1sen!2snp"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    width={600}
                    height={600}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>

                </div>
              </div>
            </div>
          </section>

          <section className="team">
            <h3 className="h4 w500 my-4">
              
              Meet our <span style={{ color: "#00BDD6" }}> Team </span>
            </h3>

            <div className="p1">
              
              <p className="h6 my-3 text-center mx-3">
              Our team at Scholib.com is dedicated to transforming education through technology. With a passion for innovation and a deep understanding of school needs, we work tirelessly to create solutions that empower educators and simplify school management. Together, we're shaping the future of education.
              </p>
            </div>

            <div className="member-container custom-scrollbar flex2">
              <div className="member flex1 mx-2">
                <img
                  src={
                    "https://res.cloudinary.com/dodvbotgd/image/upload/v1716822181/j18rrjipb2kbtjx1pi1j.jpg"
                  }
                  className="rounded-circle"
                  alt=""
                />
                <p className="h6 w600 my-3 text-capitalize"> ujjwal sapkota </p>
                <p className="h7 w300"> Co Founder </p>
                <p className="h7 w500 text-primary my-0">
                  
                  BIT
                </p>
              </div>

              <div className="member flex1 mx-2">
                <img
                  src={
                    "https://res.cloudinary.com/dodvbotgd/image/upload/v1725001088/uvqaoid8bjxvr4ulrfcl.jpg"
                  }
                  className="rounded-circle"
                  alt=""
                />
                <p className="h6 w600 my-3 text-capitalize"> Milan Bhattarai </p>
                <p className="h7 w300"> Co Founder </p>
                <p className="h7 w500 text-primary my-0">
                  BIT
                </p>
              </div>

              <div className="member flex1 mx-2">
                <img
                  src={
                    "https://res.cloudinary.com/dodvbotgd/image/upload/v1725000204/mahyuvrrisnefyopgf7a.jpg"
                  }
                  className="rounded-circle"
                  alt=""
                />
                <p className="h6 w600 my-3 text-capitalize"> Mausham Acharya </p>
                <p className="h7 w300"> Co Founder</p>
                <p className="h7 w500 text-primary my-0">
                  
                 BIT
                </p>
              </div>
            </div>
          </section>

          <section className="work">
          <p className="h4 w600"> We work to make it <span className="happen">Happen</span> </p>

          <p className="h7 text-secondary text-center udata">
          Scholib.com empowers schools with cutting-edge management tools to streamline administration, enhance communication, and elevate the educational experience. Our platform simplifies tasks, from student data management to exam scheduling, so educators can focus on what matters most—nurturing student success.
          </p>

          <div className="bottom flex2">
            
            <div className="want">
              <p className="w600 h5"> What We Want </p>
               </div>

              <div className="image">
                <img src="https://img.freepik.com/free-vector/successful-business-man-holding-trophy_1150-35042.jpg" alt="" />
                <p className="h6">Education</p>
              </div>

              <div className="image">
                <img src="https://img.freepik.com/premium-vector/cute-man-working-laptop-with-fire-cartoon-vector-icon-illustration-people-technology-isolated_138676-6247.jpg" alt="" />
                <p className="h6">Hard Work</p>
              </div>

              <div className="image">
                <img src="https://blog.ipleaders.in/wp-content/uploads/2018/01/BV-Acharya-5.jpg" alt="" />
                <p className="h6">Success</p>
              </div>

          </div>
          </section>

          <section className="terms">
            <p className="h5 w600 text-center text-secondary mt-5 mb-4"> Our Policies </p>
            <Terms />

          </section>


        </div>
      </div>
    </div>
  );
};

export default Scholib_home;

import React, { useEffect } from "react";
import "./scholib_about.scss";
import { Link } from "react-router-dom";
import MetaData from "../layout/MetaData";
const Scholib_about = () => {
  //scroll the page to the top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="yeh627vae34">
      <MetaData title={`Scholib || About Us  `} />
      <div className="main">
        <section className="top12">
          <p className="h6 w400 text-center mb-3">
            {" "}
            Hi from Scholib. We want every schools and every member directly or
            indirectly joined with us or taking our services to have a brief
            idea of who we are and what are we planning to do and managing the
            things so... Hope you will have a great experience with us{" "}
          </p>

          {/* <Link to="/login">
            {" "}
            <button className="f3"> Login To Your School </button>{" "}
          </Link> */}
        </section>
        <div className="line mt-2" style={{ height: "1.6px" }}></div>

        <section className="middle1 flex2">
          <div className="content full22">
            <div className="actual">
              <p className="h6 my-4 yebeg3" style={{ color: "#6B6984" }}>
                {" "}
                We expect living to be in an aesthetic form which is also an
                attitude. We try to apply beautiful life experience to design
                and to optimize the interaction between users and spaces.{" "}
              </p>

              <div className="text">
                <p className="h5 w500"> Ujjwal Sapkota & Team </p>
                <p className="h7 text-secondary"> Co-Founder | Scholib </p>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="images">
              <img
                src="https://scholib-public-assets.s3.ap-south-1.amazonaws.com/twoTogether.jpg"
                alt=""
              />
              <img
                src="https://scholib-public-assets.s3.ap-south-1.amazonaws.com/oneSupport.jpg"
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="middle2">
          <img
            src="https://ctfassets.imgix.net/vh7r69kgcki3/3xm70BLNewYg0oQ0uqACIq/ea3bdb8af2c9eac51de863d15f493618/Web_72DPI-VIR_TYS_COF_OFFICE.jpg?auto=format%20compress&fit=crop&q=50&w=1000&h=563"
            alt=""
          />
        </section>

        <div className="line" style={{ height: "1.6px" }}></div>

      </div>
    </div>
  );
};

export default Scholib_about;

import React, { useState, useEffect } from 'react';
import {Blurhash} from 'react-blurhash';
import './blurhash.scss'

function AnimatedBlurhashImage({ imageUrl, blurhash, width , height }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      setImageLoaded(true);
    };
  }, [imageUrl]);

  return (
    <div className="image-container">
      <div className={`blurhash-placeholder ${imageLoaded ? 'hidden' : ''}`}>
        <Blurhash hash={blurhash} width={width} height={height} />
      </div>
      <img
        src={imageUrl}
        alt="not Loaded"
        className={`actual-image ${imageLoaded ? 'visible' : 'hidden'}`}
      />
    </div>
  );
}

export default AnimatedBlurhashImage;

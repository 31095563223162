import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Dropdown from "../../basicComponents/Dropdown";
import "./analyticsOverview.scss";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../layout/Table";
import axios from "axios";
import { SET_ALERT_GLOBAL } from "../../../redux/AlertGlobalSlice";
import { GET_ACCOUNTS } from "../../../redux/HomeSlice";

const AnalyticsOverview = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const dispatch = useDispatch();
  const date = useSelector((state) => state.Other.date);
  const accounts = useSelector((state) => state.Home.accounts);

  const monthlyData = [
    { month: "January", amount: 1000, onlineAmount: 800 },
    { month: "February", amount: 1200, onlineAmount: 950 },
    { month: "March", amount: 900, onlineAmount: 720 },
    { month: "April", amount: 1100, onlineAmount: 880 },
    { month: "May", amount: 950, onlineAmount: 760 },
    { month: "June", amount: 1300, onlineAmount: 1040 },
    { month: "July", amount: 850, onlineAmount: 680 },
    { month: "August", amount: 1050, onlineAmount: 840 },
    { month: "September", amount: 1150, onlineAmount: 920 },
    { month: "October", amount: 980, onlineAmount: 784 },
    { month: "November", amount: 1250, onlineAmount: 1000 },
    { month: "December", amount: 900, onlineAmount: 720 },
  ];

  function formatDateToString(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  function convertTo12Hour(time) {
    let [hours, minutes, seconds] = time.split(":");
    hours = parseInt(hours, 10);
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes}:${seconds} ${ampm}`;
  }

  // This one is for our great select components
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const handleSelect = (option) => {
    alert(" I am from " + option);
  };

  const school = useSelector((state) => state.Home.school.payload);
  const students = useSelector((state) => state.Students.students.payload);

  useEffect(() => {
    if (accounts.length === 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/admin/${school.schoolCode}/accounts/info`,
          {
            params: {},
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.data.success) {
            const paymentData = response.data.data.paymentHistory.map(
              (ind, index) => {
                return {
                  sn: index + 1,
                  date: formatDateToString(ind.date), // in year/month/day format
                  amount: ind.amount,
                  approvedBy: school.staffs.find(
                    (sta) => sta._id === ind.approvedBy
                  ).name,
                  method: ind.method,
                  time: convertTo12Hour(ind.time),
                  student: students.find((sta) => sta._id === ind.student)?.name || 'N/A',
                };
              }
            );

            dispatch(GET_ACCOUNTS(paymentData));
          } else {
            dispatch(SET_ALERT_GLOBAL(response.data));
          }
        })
        .catch((error) => {
          console.log(error);
          const data = {
            message: error.message,
            status: "Cannot communicate with the server",
          };

          if (error.response) {
            dispatch(SET_ALERT_GLOBAL(error.response.data));
            return;
          }
          dispatch(SET_ALERT_GLOBAL(data));
        });
    }
  }, []);

  // Single function to calculate total amount for last 10 days
  function getTotalAmountLast10Days(accounts, today) {
    const todayDate = new Date(today);
    const tenDaysAgo = new Date(todayDate);
    tenDaysAgo.setDate(todayDate.getDate() - 10);

    // Helper function to convert "YYYY/MM/DD" to Date object
    const parseDate = (dateStr) => {
      const [year, month, day] = dateStr.split("/");
      return new Date(`${year}-${month}-${day}`);
    };

    // Filter and sum amounts
    return accounts
      .filter((account) => {
        const accountDate = parseDate(account.date);
        return accountDate >= tenDaysAgo && accountDate <= todayDate;
      })
      .reduce((acc, curr) => acc + curr.amount, 0);
  }

  return (
    <div className="analytics-overviewPage">
      <div className="basic-stats">
        <p className="h6 w600"> Analytics Overview</p>
        <p className="last">Today : {date}</p>
      </div>

      {/* for that Analytics overview header page let's go  */}
      <section className="analytics279">
        <div className="content-analytics flex4">
          {/* left side div  */}
          <div className="first each flex3">
            <div className="final">
              <p className="h6 w600"> Total Sales (This Year) </p>

              <div className="flex1" style={{ backgroundColor: "#EAFCFF" }}>
                <p className="h5">
                  {" "}
                  Nrs. {accounts.reduce(
                    (acc, curr) => acc + curr.amount,
                    0
                  )}{" "}
                </p>
              </div>
            </div>

            <div className="final">
              <p className="h6 w600"> Last 10 days </p>

              <div className="flex1" style={{ backgroundColor: "#EFFDF2" }}>
                <p className="h5">
                  {" "}
                  Nrs. {getTotalAmountLast10Days(accounts, date)}{" "}
                </p>
              </div>
            </div>

            {/* <div className="final">
              <p className="h6 w600"> Online Transaction </p>

              <div className="flex1" style={{ backgroundColor: "#F4F3FD" }}>
                <p className="h5"> Nrs. 0 </p>
              </div>
            </div>

            <div className="final">
              <p className="h6 w600"> Offline Transaction </p>

              <div className="flex1" style={{ backgroundColor: "#F3F5F7" }}>
                <p className="h5"> Nrs. 50,000 </p>
              </div>
            </div> */}
          </div>

          {/* right side div */}
          <div className="second each flex3">
            {/* <div className="chart final">
              <div className="vision">
                <div className="color d-inline-block">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ color: "#00BDD6" }}
                  />
                  <p className="h7 text-secondary"> Online </p>
                </div>
                <div className="color d-inline-block ms-3">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ color: "#8353E2" }}
                  />
                  <p className="h7 text-secondary"> Offline </p>
                </div>

                <div className="circle"></div>

                <p className="h6 text-center my-4 present-text">
                  100% (Offline)
                </p>
              </div>
            </div> */}

            <div
              className="more final flex3"

            >
              <div className="flex1" style={{ backgroundColor: "#F4F3FD" }}>
                <p className="h7">Total Students</p>
                <p className="h5"> {students ? students.length : "N/A"} </p>
              </div>

              <div className="flex1" style={{ backgroundColor: "#F8E0E1" }}>
                <p className="h7">Total Staffs</p>
                <p className="h5"> {school.staffs.length} </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr style={{ margin: "20px 3%", color: "grey" }} />

      <section className="amount-book flex3 pt-2">
        <div className="ind first">
          <div className="ourUp flex1">
            <p className="h6 w600 text-center d-inline-block">
              Amount Book (monthly)
            </p>
            <div className="opti d-inline-block ms-3">
              <Dropdown
                options={options}
                title={`Select One`}
                onSelect={handleSelect}
              />
            </div>
          </div>

          <div className="payment-history custom-scrollbar">
            <Table data={monthlyData} fields={["Month", "Amount", "Online"]} />
          </div>
        </div>

        <div className="line"></div>

        <div className="ind second">
          <div className="ourUp flex1">
            <p className="h6 w600 text-center d-inline-block">
              Amount Book (daily)
            </p>
          </div>
          <div
            className="payment-history custom-scrollbar"
            style={{ overflow: "auto" }}
          >
            <Table data={monthlyData} fields={["Month", "Amount", "Online"]} />
          </div>
        </div>
      </section>

      <hr style={{ margin: "20px 3%", color: "grey" }} />

      {false && (
        <>
          <div className="history-bar">
            <p className="h6 text-center w600"> Scholib Transaction History </p>
          </div>

          <section className="amount-book flex3 pt-2">
            <div className="ind first">
              <div
                className="ourUp flex1"
                style={{ justifyContent: "flex-end" }}
              >
                <div className="opti d-inline-block ms-3">
                  <Dropdown
                    options={options}
                    title={`2080`}
                    onSelect={handleSelect}
                  />
                </div>
              </div>

              <div className="payment-history custom-scrollbar">
                <Table
                  data={monthlyData}
                  fields={["Month", "Amount", "Online"]}
                />
              </div>
            </div>

            <div className="line"></div>

            <div className="ind second">
              <div className="updateHere">
                <div className="date">
                  <p className="h7 text-secondary">2080-09-17 | 2:00:37 PM</p>
                </div>

                <div className="author flex1">
                  <img
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                    alt="user"
                  />
                  <p className="h6 w500 text-secondary mx-3">
                    Ujjwal Sapkota
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="mx-1 text-primary"
                    />
                  </p>

                  <p className="h7 text-secondary w400 author-title">
                    CEO | Scholib
                  </p>
                </div>

                <div className="content">
                  <div className="text">
                    <p className="h6 f2 w400 text-dark text-center">
                      " Dear Administrators Scholib Stores your school's
                      transaction and it will be shown in your dashboard and
                      collected amount will be sent to your associated bank
                      account once in a week if you need to collect money in
                      emergency do contact us or if you want to change your
                      billing details feel free to contact us for further
                      assistance. all transactions and details are stored safely
                      so in case of any querirs we are always ready for your
                      support so feel free. "
                    </p>
                  </div>

                  <div className="images">
                    {/* <Albums01 data={update.images} /> */}

                    <img
                      src="https://cdn.pixabay.com/photo/2023/12/19/15/51/flowers-8457960_640.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default AnalyticsOverview;

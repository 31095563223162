import React, { useEffect, useState } from "react";
import "./attendance.scss";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import Dropdown from "../basicComponents/Dropdown";
import DataTable from "../layout/Table";
import { SET_ALERT_GLOBAL } from "../../redux/AlertGlobalSlice";
import axios from "axios";

const Attendance = () => {
  const user = useSelector((state) => state.User.user.payload);
  const school = useSelector((state) => state.Home.school.payload);
  const courses = useSelector((state) => state.Course.course.payload.course);
  const date = useSelector((state) => state.Other.date);
  const dispatch = useDispatch();

  const [students, setStudents] = useState(null);

  const [currentClass, setCurrentClass] = useState(
    courses.length > 0 ? courses[0] : null
  );

  function getAllSectionIds() {
    if (currentClass === null) {
      return [];
    }

    let sectionIds = [];

    currentClass.groups.forEach((group) => {
      group.sections.forEach((section) => {
        sectionIds.push(section);
      });
    });

    return sectionIds;
  }

  const [allSections, setAllSections] = useState(
    getAllSectionIds(
      courses.find((crc) => {
        return crc._id === currentClass;
      })
    )
  );

  const [currentSection, setCurrentSection] = useState(allSections[0]);

  useEffect(() => {
    setAllSections(getAllSectionIds());
  }, [currentClass]);

  useEffect(() => {
    setCurrentSection(allSections[0]);
  }, [allSections]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function getStudents() {
    let groupId = null;

    currentClass &&
      currentClass.groups.forEach((grp) => {
        if (grp.sections.some((sec) => sec._id === currentSection._id)) {
          groupId = grp._id;
        }
      });

    if (!currentClass) {
      return;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/adminStudent/${school.schoolCode}/advanced/students`,
        {
          params: {
            classId: currentClass._id,
            groupId: groupId,
            sectionId: currentSection._id,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.success) {
          setStudents(response.data.data);
        } else {
          dispatch(SET_ALERT_GLOBAL(response.data));
        }
      })
      .catch((error) => {
        const data = {
          message: error.message,
          status: "Cannot communicate with the server",
        };

        if (error.response) {
          dispatch(SET_ALERT_GLOBAL(error.response.data));
          return;
        }
        dispatch(SET_ALERT_GLOBAL(data));
      });
  }

  const [markedStudents, setMarkedStudents] = useState([]);

  function handlSelectedOnes(arr) {
    setMarkedStudents(
      arr.map((data) => {
        return students[data]._id;
      })
    );
  }

  useEffect(() => {
    getStudents();
  }, [currentSection]);

  function addAsAbsent() {
    let groupId = null;

    currentClass.groups.forEach((grp) => {
      if (grp.sections.some((sec) => sec._id === currentSection._id)) {
        groupId = grp._id;
      }
    });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/adminStudent/${school.schoolCode}/advanced/admission/take`,
        {
          params: {
            classId: currentClass._id,
            groupId: groupId,
            sectionId: currentSection._id,
            absentStudents: JSON.stringify(markedStudents),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setLoading(false);
        setOurAlert(false);

        if (response.data.success) {
          dispatch(SET_ALERT_GLOBAL(response.data));
        } else {
          dispatch(SET_ALERT_GLOBAL(response.data));
        }
      })
      .catch((error) => {
        const data = {
          message: error.message,
          status: "Cannot communicate with the server",
        };

        if (error.response) {
          setLoading(false);
          setOurAlert(false);
          dispatch(SET_ALERT_GLOBAL(error.response.data));
          return;
        }
        setLoading(false);
        setOurAlert(false);
        dispatch(SET_ALERT_GLOBAL(data));
      });
  }

  const [ourAlert, setOurAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className="attendanceAdmin2838">
      <MetaData
        title={`${user && user.role ? "Staff" : "Student"} || Attendance`}
      />

      {ourAlert && students && (
        <div className="finalAlert flex1">
          <div className="finalInside custom-scrollbar">
            <div className="inxsds">
              <p className="h5 w600 text-danger text-center">
                {" "}
                Confirm Changes{" "}
              </p>
              <hr />
              <p className="h6 text-secondary text-center px-3">
                {" "}
                Are you sure the list of absent students are correct ?
              </p>

              <hr />

              <div className="absentStudents">
                <DataTable
                  data={students
                    .map((student, index) => {
                      if (markedStudents.includes(student._id)) {
                        return {
                          sn: index + 1,
                          name: student.name,
                          absentDays: student.absentdays
                            ? student.absentdays.length
                            : 0,
                          _id: student._id,
                        };
                      } else {
                        return null;
                      }
                    })
                    .filter((student) => student !== null)}
                  fields={["Roll", "Student Name", "Absent Days"]}
                  exclude={["_id"]}
                />
              </div>

              <div className="buttons flex4 pt-3">
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setOurAlert(false);
                    setMarkedStudents([]);
                  }}
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    addAsAbsent();
                    setLoading(true);
                  }}
                >
                  {" "}
                  Submit{" "}
                </button>
              </div>
            </div>

            {loading && (
              <div
                className="spinner-container flex1"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  backgroundColor: "#fff",
                }}
              >
                <div
                  className="spinner-border text-primary my-4 loading452"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="inside-content">
        <div className="veryTop">
          <p className="h4 text-center" style={{ color: "#133189" }}>
            Attendance
          </p>
          <p className="h6 text-center"> {school.name} </p>
        </div>
      </div>

      {!ourAlert && courses && courses.length > 0 && (
        <div className="inside-content">
          {/* <div className="veryTop">
            <p className="h4 text-center" style={{ color: "#133189" }}>
              Attendance
            </p>
            <p className="h6 text-center"> {school.name} </p>
          </div> */}

          <div className="centerOne">
            <div className="righterOne flex1">
              <div
                className="each flex1 ms-2 ps-2"
                style={{ marginTop: "8px" }}
              >
                {" "}
                <p className="h7 w500"> Class : </p>{" "}
                <Dropdown
                  title={currentClass && currentClass.class}
                  options={courses.map((ind) => {
                    return {
                      label: ind.class,
                      value: ind._id,
                    };
                  })}
                  onSelect={(a, b, c) => {
                    setCurrentClass(
                      courses.find((ind) => {
                        return ind._id === c;
                      })
                    );
                  }}
                />{" "}
              </div>
              <div
                className="each flex1 ms-2 ps-2"
                style={{ marginTop: "8px" }}
              >
                {" "}
                <p className="h7 w500"> Section : </p>{" "}
                <Dropdown
                  options={allSections.map((sec) => {
                    return {
                      label: sec.name,
                      value: sec._id,
                    };
                  })}
                  onSelect={(a, b, c) => {
                    setCurrentSection(
                      allSections.find((ind) => {
                        return ind._id === c;
                      })
                    );
                  }}
                  title={currentSection && currentSection.name}
                />
              </div>
            </div>

            {students && (
              <div className="below">
                <p
                  className="h5 f2 text-center pb-2 w600"
                  style={{ color: "#333843" }}
                >
                  Student Attendance Table
                </p>

                <hr />

                <p className="w600 h6 text-center text-secondary"> ** Select the students who were absent **</p>

                <hr />

                <div
                  className="mt-3 custom-scrollbar"
                  style={{ overflowX: "auto" }}
                >
                  <DataTable
                    data={students.map((student, index) => ({
                      sn: index + 1,
                      name: student.name,
                      absentDays: `${
                        student.absentdays ? student.absentdays.length : 0
                      }`,
                      _id: student._id,
                    }))}
                    fields={["SN", "Student Name", "Absent Days"]}
                    exclude={["_id"]}
                    selectedOnes={handlSelectedOnes}
                  />

                  <hr />

                  <div className="button flex1">
                    <button
                      className="btn btn-secondary"
                      onClick={() => setOurAlert(true)}
                      style={{ width: "100%", fontSize: "13px" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {courses && courses.length <= 0 && (

        <>
        <hr />
        <p className="h6 text-center text-secondary py-1 w600">
          {" "}
          No Courses Available{" "}
        </p>
        <hr />
        
        </>
      )}
    </div>
  );
};

export default Attendance;

import React, { useEffect, useState } from "react";
import "./library.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MetaData from "../layout/MetaData";
import { useSelector } from "react-redux";
import Table from "../layout/Table";
import Dropdown from "../basicComponents/Dropdown";

const Library = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const user = useSelector((state) => state.User.user.payload);

  const feeStructure = [
    {
      category: "Tuition Fee",
      amount: 5000,
      frequency: "per semester",
    },
    {
      category: "Library Fee",
      amount: 200,
      frequency: "per year",
    },
    {
      category: "Sports Fee",
      amount: 300,
      frequency: "per semester",
    },
    {
      category: "Exam Fee",
      amount: 150,
      frequency: "per term",
    },
    {
      category: "Transportation Fee",
      amount: 800,
      frequency: "per month",
    },
    {
      category: "Miscellaneous Fee",
      amount: 100,
      frequency: "one-time",
    },
  ];

  // This one is for our great select components
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const handleSelect = (option) => {
    alert(" I am from " + option);
  };

  const studentData = useSelector(
    (state) => state.StudentData.studentData.payload
  );

  const [returnedBooks, setReturnedBooks] = useState(
    studentData
      ? studentData.student.library.filter((book) => book.returnedDate)
      : []
  );

  const [notReturnedBooks, setNotReturnedBooks] = useState(
    studentData
      ? studentData.student.library.filter((book) => !book.returnedDate)
      : []
  );

  return (
    <div className="libraryComponent2366 flex2">
      <MetaData
        title={`${user && user.role ? "Staff" : "Student"} || Library`}
      />
      <div className="mainyy" style={{marginTop:'1vw'}}>
        <section className="library">
          <div className="top flex3" style={{paddingTop:'1vw'}}>
            <div className="each px-4" style={{ backgroundColor: "#F4F2FC" }}>
              {" "}
              <p className="h7 w500">Books Taken </p>{" "}
              <p className="h5 mt-2"> {studentData.student.library.length} </p>{" "}
            </div>
            <div
              className="each px-4 ms-2"
              style={{ backgroundColor: "#F0F4FC" }}
            >
              {" "}
              <p className="h7 w500">To Return </p>{" "}
              <p className="h5 mt-2"> {notReturnedBooks.length} </p>{" "}
            </div>
            <div
              className="each px-4 ms-2"
              style={{ backgroundColor: "#F9E0E0" }}
            >
              {" "}
              <p className="h7 w500">Books Returned </p>{" "}
              <p className="h5 mt-2"> {returnedBooks.length} </p>{" "}
            </div>
          </div>

          <div className="nearest-Returns my-3">
            <p className="h6 w600 text-center py-2">Not Returned Books</p>

            <div className="for-table custom-scrollbar mt-4">
            <Table data={notReturnedBooks.map((int)=>{
                return({
                  name: int.book,
                  date: int.date.split("T")[0],
                  returnedDate: int.returnDate.split("T")[0]
                })
              })}
              fields={['Book Name', 'Date Taken', 'Return Date']}
              />
            </div>

            <hr />
          </div>

          <div className="libraryHistory26">
            <div
              className="myline flex1"
              style={{ justifyContent: "flex-start" }}
            >
              <p className="h6 w600 text-center" style={{width:'100%'}}> Returned Books </p>

              {/* <div className="myDropdown flex1">
                <Dropdown
                  options={options}
                  // title={`Select One`}
                  onSelect={handleSelect}
                />
              </div> */}


            </div>

            <div className="for-table custom-scrollbar mt-4">
              <Table data={returnedBooks.map((int)=>{
                return({
                  name: int.book,
                  date: int.date.split("T")[0],
                  returnedDate: int.returnedDate.split("T")[0]
                })
              })}
              fields={['Book Name', 'Date Taken', 'Returned Date']}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Library;

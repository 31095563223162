import React, { useEffect, useState } from "react";
import "./tableEdit.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

const TableEdit2 = ({
  data,
  fields,
  selectedOnes,
  exclude = [],
  excludedKeys = [],
  function1 =()=>{}
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [myData, setMyData] = useState(data);
  const [editIndex, setEditIndex] = useState(null);
  

  function selectRow(index) {
    if (index !== editIndex) {
      setEditIndex(null);
    }
  }

  function handleEditClick(e, index) {
    e.stopPropagation();

    if (editIndex === index) {
      setEditIndex(null);
    } else {
      setEditIndex(index);
    }
  }

  function handleInputChange(event, index, field) {
    const { value } = event.target;
    setMyData((prevData) => {
      const newData = [...prevData];
      newData[index][field] = value; // Update the value of the field in the corresponding row
      return newData;
    });
  }

  useEffect(() => {
    setMyData(data);
  }, [data]);


//   async function deleteBusRoute (index){

//     axios
//     .delete(
//       `${process.env.REACT_APP_API_URL}/admin/${school.schoolCode}/busRoute/${myData[index]._id}`,
//       {
//         withCredentials: true,
//       }
//     )
//     .then((response) => {
//       if (response.data.success) {

//           setEditIndex(null);
//           setMyData(() => {
//             const newData = [...myData];
//             newData.splice(index, 1);
//             return newData;
//           });

//         dispatch(SET_ALERT_GLOBAL(response.data));



//       } else {
//         dispatch(SET_ALERT_GLOBAL(response.data));
//       }
//     })
//     .catch((error) => {
//       const data = {
//         message: error.message,
//         status: "Cannot communicate with the server",
//       };

//       if (error.response) {
//         dispatch(SET_ALERT_GLOBAL(error.response.data));
//         return;
//       }
//       dispatch(SET_ALERT_GLOBAL(data));
//     });


//   }

  return (
    <div className="heroTable0238">
      {!myData || myData.length < 1 ? (
        <p className="h6 text-secondary text-center"> No data Found... </p>
      ) : (
        <table>
          <thead>
            {/* header row of the legendary table  */}
            <tr>
              {!fields &&
                Object.keys(myData[0]).map((key) => {
                  if (!exclude.includes(key) && !excludedKeys.includes(key)) {
                    return <th key={key}>{key}</th>;
                  }
                  return null;
                })}

              {fields &&
                fields.map((key) =>
                  key === "" ? (
                    <th
                      style={{ paddingLeft: "13px", paddingRight: "13px" }}
                      key={key}
                    >
                      {key}
                    </th>
                  ) : (
                    <th key={key}>{key}</th>
                  )
                )}
            </tr>
          </thead>
          <tbody>
            {/* main row of the legendary table  */}
            {myData.map((row, index) => (
              <tr
                key={index}
                className={selectedRows.includes(index) ? "selected" : ""}
                onClick={() => selectRow(index)}
              >
                {Object.keys(row).map((key, i) => {
                  if (!exclude.includes(key) && !excludedKeys.includes(key)) {
                    return (
                      <td key={i}>
                        {editIndex === index &&
                        !exclude.includes(key) &&
                        !excludedKeys.includes(key) ? (
                          <input
                            className="input-hero1"
                            type="text"
                            value={row[key]}
                            onChange={(event) =>
                              handleInputChange(event, index, key)
                            }
                          />
                        ) : (
                          row[key]
                        )}
                      </td>
                    );
                  }
                  return null;
                })}
                <td
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    cursor: "pointer",
                    borderRight: "0px",
                  }}
                  // Handle click for editing
                >
                  <div
                    className="svg"
                    onClick={(e) => {
                      handleEditClick(e, index);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={editIndex === index ? faCheck : faPen}
                    />
                  </div>
                </td>
                <td
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    cursor: "pointer",
                    borderLeft: "0px",
                  }}
                  onClick={(e) => {
                    // e.stopPropagation();

                    function1(myData[index]._id);


                    setEditIndex(null);


                    // setMyData((prevData) => {
                    //   const newData = [...prevData];
                    //   newData.splice(index, 1);
                    //   return newData;
                    // });


                  }}
                >
                  <div className="svg">
                    <FontAwesomeIcon icon={faTrash} className="text-danger" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TableEdit2;

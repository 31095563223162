import React, { useEffect, useState } from "react";
import "./students.scss";
import { useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import StudentSearch from "../adminComponents/StudentSearch";
import Dropdown from "../basicComponents/Dropdown";
import StudentDetails from "./StudentDetails";
import DataTable from "../layout/Table";

const Students = () => {
  const user = useSelector((state) => state.User.user.payload);
  const school = useSelector((state) => state.Home.school.payload);
  const courses = useSelector((state) => state.Course.course.payload.course);

  const students = useSelector((state) => state.Students.students.payload);

  const [currentStudent, setCurrentStudent] = useState(null);

  if (currentStudent) {
    document.body.classList.add("dshauda-hidden322");
  } else {
    document.body.classList.remove("dshauda-hidden322");
  }

  function setStudentFunction(id) {
    setCurrentStudent(id);
  }

  const [currentClass, setCurrentClass] = useState(
    courses.length > 0 ? courses[0] : null
  );

  function getAllSectionIds() {
    if (currentClass === null) {
      return [];
    }

    let sectionIds = [];

    currentClass.groups.forEach((group) => {
      group.sections.forEach((section) => {
        sectionIds.push(section);
      });
    });

    return sectionIds;
  }

  const [allSections, setAllSections] = useState(
    getAllSectionIds(
      courses.find((crc) => {
        return crc._id === currentClass;
      })
    )
  );

  const [currentSection, setCurrentSection] = useState(allSections[0]);

  useEffect(() => {
    setAllSections(getAllSectionIds());
  }, [currentClass]);

  useEffect(() => {
    setCurrentSection(allSections[0]);
  }, [allSections]);

  return (
    
    <div className="studentsAdmin2838">
      <MetaData
        title={`${user && user.role ? "Staff" : "Student"} || Students`}
      />

      {currentStudent && (
        <div className="student-details-container2212 custom-scrollbar">
          <StudentDetails
            _id={currentStudent}
            closeFunction={() => setCurrentStudent(null)}
          />
        </div>
      )}

      <div className="inside-content">
        <div className="veryTop">
          <p className="h4 text-center" style={{ color: "#133189" }}>
            Students
          </p>
          <p className="h6 text-center"> {school.name} </p>
        </div>

        <div className="here-main2638">
          <StudentSearch setStudent={setStudentFunction} />

          <div className="students-table-3283">
            <p className="h4 pt-3 pb-2 w600 text-center"> Students </p>

            <div className="bar-control flex1 mt-3">
              <div className="ind-bar flex1">
                {" "}
                <p className="h6 w500 me-2"> Class : </p>{" "}
                <Dropdown
                  title={currentClass && currentClass.class}
                  options={courses.map((ind) => {
                    return {
                      label: ind.class,
                      value: ind._id,
                    };
                  })}
                  onSelect={(a, b, c) => {
                    setCurrentClass(
                      courses.find((ind) => {
                        return ind._id === c;
                      })
                    );
                  }}
                />{" "}
              </div>
              <div className="ind-bar flex1">
                {" "}
                <p className="h6 w500 me-2"> Section : </p>{" "}
                <Dropdown
                  options={allSections.map((sec) => {
                    return {
                      label: sec.name,
                      value: sec._id,
                    };
                  })}
                  onSelect={(a, b, c) => {
                    setCurrentSection(
                      allSections.find((ind) => {
                        return ind._id === c;
                      })
                    );
                  }}
                  title={currentSection && currentSection.name}
                />{" "}
              </div>
            </div>

            <hr />
          </div>
        </div>

        {students && students.length > 0 && (
          <div className="mt-3 custom-scrollbar" style={{ overflowX: "auto" }}>
            <DataTable
              data={students
                .filter(
                  (student) =>
                    student.course.class === currentClass._id &&
                    student.course.section === currentSection._id
                )
                .map((student, index) => ({
                  sn: index + 1,
                  name: student.name,
                  loginId: student.loginId,
                }))}
              fields={["SN", "Student Name", "Login Id"]}
            />
          </div>
        )}
      </div>
    </div>


  );
};

export default Students;

import React, { useState, useEffect, useRef } from "react";
import "./scholib_search.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const SearchComponent = ({ suggestions, search10 }) => {

  const history = useHistory()

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchRef = useRef(null); // Create a ref for the input element.
  const eltRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSuggestions(filteredSuggestions);
  }, [searchTerm, suggestions]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setShowSuggestions(value.trim() !== ""); // Show suggestions if the input is not empty.
  };

  const handleSuggestionClick = (suggestion) => {

    const schoolCode = parseInt(suggestion.match(/^\d{6}/));
    search10();
    history.push(`/school/${schoolCode}`);
  };

  const handleInputFocus = () => {
    setInputFocused(true);
    setShowSuggestions(searchTerm.trim() !== ""); // Show suggestions when focused with non-empty input.
  };

  const handleInputBlur = () => {
    setInputFocused(false);
    // setShowSuggestions(false);
  };

  function handleSearchClose (e){
    search10(false);
  }



  return (
    <div ref={eltRef} className="seardasydg56262">
      <div className="main">

    <div ref={searchRef} className="search-container">

      <div className="close flex1" onClick={handleSearchClose} style={{cursor: 'pointer'}}>
      <FontAwesomeIcon icon={faXmark} />
      </div>

      <input
        type="text"
        placeholder="Search for your school..."
        className="search-bar"
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        ref={inputRef}
      />


<ul className={`suggestion-list ${showSuggestions ? "" : "d-none"}`}>
  {filteredSuggestions.length === 0 ? (
    <p className="h6 my-2 mx-3 text-secondary">School not found</p>
  ) : (
    filteredSuggestions.map((suggestion) => (
      <li className="suggestion-item h6 d-flex" key={suggestion} onClick={() => handleSuggestionClick(suggestion)}>
        <FontAwesomeIcon className="mx-2 text-secondary" icon={faMinus} />
        <p style={{ marginBottom: 0 }}> {suggestion} </p>
      </li>
    ))
  )}
</ul>
      </div>
      </div>
    </div>
  );
};

export default SearchComponent;




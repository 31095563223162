import React, { useEffect, useState } from "react";
import "./dropdown.scss"; // Assuming you have a stylesheet for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const Dropdown = ({
  options = [
    {
      title: "Option 1",
      value: "Value 1",
    },
    {
      title: "Option 2",
      value: "Value 2",
    },
  ],
  title,
  onSelect = () => {},
  key11,
}) => {
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(title); // Initialize with title prop

  useEffect(() => {
    setCurrent(title); // Update current when title prop changes
  }, [title]);

  const handleShow = () => {
    setShow(!show);
  };

  const handleSelect = (index) => (e) => {
    onSelect(index, key11, options[index].value);
    setCurrent(options[index].label);
  };

  return (
    <div className="dropdown973753" onClick={handleShow}>
      <p className="text-center">
        {current} &nbsp; <FontAwesomeIcon icon={faCaretDown} />
      </p>

      {show && (
        <div className="container">
          {options && options.length > 0 && (
            <ul>
              {/* Map options to list items */}
              {options.map((obj, index) => (
                <li key={index} className="each" onClick={handleSelect(index)}>
                  <p className="text-center">{obj.label}</p>
                </li>
              ))}
            </ul>
          )}

          {!options ||
            (options.length <= 0 && (
              <div className="each2">
                <p className="text-center">No Data</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

import React,{useEffect} from "react";
import "./fees.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faLock, faSignOut } from "@fortawesome/free-solid-svg-icons";
import MetaData from "../layout/MetaData";
import { useSelector } from "react-redux";
import Table from "../layout/Table";

const Fees = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  const user = useSelector((state) => state.User.user.payload);
  const studentData = useSelector(
    (state) => state.StudentData.studentData.payload
  );

  const feeStructure = [
    {
      category: "Tuition Fee",
      amount: 5000,
      frequency: "per semester",
    },
    {
      category: "Library Fee",
      amount: 200,
      frequency: "per year",
    },
    {
      category: "Sports Fee",
      amount: 300,
      frequency: "per semester",
    },
    {
      category: "Exam Fee",
      amount: 150,
      frequency: "per term",
    },
    {
      category: "Transportation Fee",
      amount: 800,
      frequency: "per month",
    },
    {
      category: "Miscellaneous Fee",
      amount: 100,
      frequency: "one-time",
    },
  ];
  

  return (
    <div className="feesComponent325375 flex2">
      <MetaData title= {`${(user && user.role) ? "Staff" : "Student"} || Fees`} />
      <div className="mainyy">

        <section className="fees">
          <p className="h4 w600 my-3 pb-3 d-none titleText"> Fees : </p>

          <section className="account">

            {/* <div className="topNewCommented">

          <div className="abs-container">
            <div className="each left flex1">
              <Table />
            </div>
            <div className="each right">
              <div className="color d-inline-block mx-3">
                <FontAwesomeIcon icon={faCircle} style={{ color: "#00BDD6" }} />
                <p className="h7 text-secondary"> Paid </p>
              </div>
              <div className="color d-inline-block mx-3">
                <FontAwesomeIcon icon={faCircle} style={{ color: "#8353E2" }} />
                <p className="h7 text-secondary"> Unpaid </p>
              </div>

              <div className="circle"></div>

              <p className="h6 text-center my-4 present-text">
                
                100% (paid)
              </p>
            </div>
          </div>

          <div className="pay">
            <p className="h6 w500 pt-2 pb-2"> Enter amount Rs.</p>
            <input type="text" name="" id="" placeholder='' />
            <button className="btn btn-success" style={{fontSize: '14px'}}> Pay Now</button>
          </div>
            </div> */}

          <section className="paymentDetails">
          <p className="h5 w600 post-text-center f2">
            Payment History
          </p>

          <div className="payment-history custom-scrollbar">
            {/* <Table data={paymentDetails} /> */}



            <Table
                  data={studentData.student.paymentHistory.map((ind) => {
                    return {
                      amount: ind.amount,
                      date: ind.date.split("T")[0],
                      time: ind.time,
                      method: ind.method,
                    };
                  })}
                  fields={["Amount (Rs.)", "Date", "Time", "Method"]}
                  exclude={["_id"]}
                />



          </div>

          <div className="payment-info flex2">
            <div className="paid">
              
              <p className="h6 w600 text-secondary">
                
                AMOUNT PAID :
                <span className="w500 text-black ms-2"> Rs. 30,000 </span>
              </p>
            </div>
            <div className="left">
              
              <p className="h6 w600 text-danger">
                
                AMOUNT LEFT :
                <span className="w500 text-black ms-2"> Rs. 60,000 </span>
              </p>
            </div>
          </div>

        </section>

        <section className="paymentDetails">
          <p className="h5 post-text-center w600 f2" style={{marginTop:'40px'}}>
            Fee Structure (Yearly)
          </p>

          <div className="payment-history custom-scrollbar">
            <Table data={feeStructure} />
          </div>

        </section>

        </section>

        </section>
      </div>
    </div>
  );
};

export default Fees;

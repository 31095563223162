import axios from "axios";
import React, { useEffect, useState } from "react";

const CalculateStudentBus = () => {
  const [date, setDate] = useState();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/basic/nepaliDate/v1`, {
        withCredentials: true,
      })
      .then((response) => {
        setDate(response.data.data.nepaliDate);
      });
  }, []);

  const dataArray = [
    {
      place: "6227ddc53a25ad7360e5f4cf",
      start: "2081-01-01T00:00:00.000Z",
    },

    {
      place: "6227ddc53a25cd7360e5f4cf",
      start: "2080-05-12T00:00:00.000Z",
      end: "2080-07-12T00:00:00.000Z",
    },

    {
      place: "6627ddc53a25cd7360e5f4cf",
      start: "2070-01-01T00:00:00.000Z",
      end: "2080-03-20T00:00:00.000Z",
    },
  ];

  const priceArray = [
    {
      location: "6627ddc53a25cd7360e5f4cf",
      amounts: [
        { date: "2081-01-11T00:00:00.000Z", amount: 1310 },
        { date: "2080-04-11T00:00:00.000Z", amount: 2330 },
        { date: "2070-01-01T00:00:00.000Z", amount: 1130 },
      ],
    },
    {
      location: "6227ddc53a25cd7360e5f4cf",
      amounts: [
        { date: "2081-01-11T00:00:00.000Z", amount: 1800 },
        { date: "2080-04-11T00:00:00.000Z", amount: 1900 },
        { date: "2080-01-01T00:00:00.000Z", amount: 1000 },
      ],
    },
    {
      location: "6227ddc53a25ad7360e5f4cf",
      amounts: [
        { date: "2081-01-01T00:00:00.000Z", amount: 1000 },
        { date: "2080-04-11T00:00:00.000Z", amount: 1900 },
        { date: "2080-01-01T00:00:00.000Z", amount: 1000 },
      ],
    },
  ];

  function priceCalculator(
    date = "2080-02-01",
    dataArray,
    priceArray,
    date2 = "2080-01-01"
  ) {
    const getDaysDifference = (date2, date1) => {
      const oneDay = 24 * 60 * 60 * 1000;

      const firstDate = new Date(date1);
      const secondDate = new Date(date2);

      firstDate.setHours(0, 0, 0, 0);
      secondDate.setHours(0, 0, 0, 0);

      const timeDifference = firstDate.getTime() - secondDate.getTime();
      const daysDifference = Math.round(timeDifference / oneDay);

      return daysDifference;
    };

    let totalPrice = 0;

    dataArray.forEach((dataItem) => {
      const { place, start, end } = dataItem;
      const priceItem = priceArray.find((item) => item.location === place);

      if (priceItem) {
        const { amounts } = priceItem;
        let startDate = new Date(start);

        if (getDaysDifference(startDate, date2) > 0) {
          startDate = date2;
        }

        let endDate = end ? new Date(end) : new Date(date);

        amounts.forEach((each) => {
          let daysdif = getDaysDifference(each.date, endDate);

          if (daysdif > 0) {
            let interval = getDaysDifference(each.date, endDate);
            let interval2 = getDaysDifference(each.date, startDate);

            if (interval2 > 0) {
              interval = interval - interval2;
            }

            totalPrice += (each.amount / 30) * interval;
          }
        });
      }
    });

    return Math.ceil(totalPrice);
  }

  return (
    <div className="flex1" style={{ height: "100vh", flexDirection: "column" }}>
      <p className="h6 text-center"> Hi, Calculating Bus Fee ready to go </p>
      <p className="h5 w500 text-center text-secondary"> - Ujjwal Sapkota </p>
    </div>
  );
};

export default CalculateStudentBus;

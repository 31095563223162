import React, { useEffect, useState } from 'react';
import './game.scss'


const ShuffleComponent = () => {
  // Initial array with 4 elements
  const initialArray = ['25', '15', '0', '0'];
  const initialArray2 = ['USHA', 'UJJWAL', 'ANUPAMA', 'SANI'];

  // State to store the shuffled array
  const [shuffledArray, setShuffledArray] = useState(initialArray);
  const [shuffledArray2, setShuffledArray2] = useState(initialArray2);

  // State to handle loading status
  const [isLoading, setIsLoading] = useState(false);

  // Function to shuffle the array
  const shuffleArray = (array) => {
    // Create a copy of the array and shuffle it
    const shuffled = [...array].sort(() => Math.random() - 0.5);
    return shuffled;
  };


  // Handle button click to shuffle the array with a 1-second delay
  const handleShuffle = () => {
    setIsLoading(true); // Show loading message

    setTimeout(() => {
      setShuffledArray(shuffleArray(shuffledArray));
      setShuffledArray2(shuffleArray(shuffledArray2));
      setIsLoading(false); // Hide loading message after 1 second
    }, 1000);
  };



    // Function to handle keypress (Enter key)
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !isLoading) {
          handleShuffle();
        }
      };
    
      // Add event listener for keydown when the component mounts
      useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };
      }, [isLoading]); // Dependency on isLoading to prevent shuffle while already loading

  return (
    <div className='main221'>
      {isLoading ? (
        <div>Loading...</div> // Display loading message while shuffling
      ) : (
        <>


          <div>

            <p className="h6"> {shuffledArray2[0]} </p>
            
            {shuffledArray[0]}
            
            </div>


            <div>

<p className="h6">  {shuffledArray2[1]} </p>

{shuffledArray[1]}

</div>

<div>

<p className="h6">  {shuffledArray2[2]} </p>

{shuffledArray[2]}

</div>


<div>

<p className="h6">  {shuffledArray2[3]} </p>

{shuffledArray[3]}

</div>

        </>
      )}
      <button onClick={handleShuffle} disabled={isLoading}>Shuffle Elements</button>
    </div>
  );
};

export default ShuffleComponent;
